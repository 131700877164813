<template>
  <div
    @mouseleave="actionShown = false"
    @mouseover="actionShown = true"
    :class="$style.wrapper"
  >
    <slot></slot>
    <div
      :class="[
        $style.wrapper_ActionContainer,
        $style[`wrapper_ActionContainer--${actionShown ? 'shown' : 'hidden'}`],
      ]"
    >
      <slot name="action-btn"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      actionShown: false,
    };
  },
};
</script>

<style lang="scss" module>
.wrapper {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}
.action_image {
  display: block;
  // width: 100%;
  margin: 0 auto;
  max-width: 100%;

  object-fit: cover;
}
.wrapper_ActionContainer {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.272);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
}
.wrapper_ActionContainer--shown {
  bottom: 0;
}
.wrapper_ActionContainer--hidden {
  bottom: -100%;
}
</style>
