<script>
import { mapGetters } from "vuex";
import ActionContainer from "../../../components/ActionContainer.vue";
import dropdownCategoriesSelect from "../../../components/dropdownCategoriesSelect.vue";
import dropdownSelectAll from "../../../components/dropdownSelectAll.vue";
import {
  providersService,
  sectionsService,
  regionsService,
  // retailersService,
} from "../../../services";
import extendedFunctions from "../../../utils/extendedFunctions";
export default {
  name: "AdDetailsCard",
  components: { dropdownCategoriesSelect, dropdownSelectAll, ActionContainer },
  props: {
    value: {
      type: Object,
    },
    disabled: { type: Boolean, default: false },
    adPublished: { type: Boolean, default: false },
    isCollapsed: { type: Boolean, default: true },
  },
  data() {
    // eslint-disable-next-line no-unused-vars
    function validateDate({ rule, value, data, callback }) {
      const currentDate = new Date();
      const selectedDate = new Date(value);

      if (selectedDate < currentDate && data == null) {
        return callback(
          new Error("Please select a date greater than the current date.")
        );
      } else {
        callback();
      }
    }

    function validateStartDate(rule, value, callback) {
      validateDate({ rule, value, data: this.editData, callback });
    }

    function validateEndDate(rule, value, callback) {
      validateDate({ rule, value, data: this.newData, callback });
    }

    return {
      // usersList: [],
      adObj: {
        ...this.value,
        name: this.value.name,
        sectionsList: null,
        entitiesExcelFileList: [],
        uploadingExcelFile: false,
        // navigation: "",
        // targeting: "",
      },
      targetOptions: [
        { label: "Section", key: "section" },
        { label: "Provider", key: "provider" },
        { label: "Both", key: "both" },
      ],
      adRules: {
        name: [
          {
            message: this.$t("HandleAdsPageAdNameValidation"),
            trigger: "change",
            required: true,
          },
        ],
        deep_link: [
          {
            message: "deeplink is Required",
            trigger: "change",
            // required: true,
          },
        ],
        imageUrl: [
          {
            message: this.$t("handleAdsPageValidationImage"),
            trigger: "change",
            required: true,
          },
        ],
        videoUrl: [
          {
            message: this.$t("handleAdsPageValidationVideo"),
            trigger: "change",
            required: true,
          },
        ],
        sections: [
          {
            message: this.$t("handleAdsPageValidationSection"),
            trigger: "change",
            required: true,
          },
        ],
        sectionsNavigation: [
          {
            message: this.$t("handleAdsPageValidationSection"),
            trigger: "change",
            required: true,
          },
        ],
        areas: [
          {
            message: this.$t("handleAdsPageValidationArea"),
            trigger: "change",
            required: true,
          },
        ],
        providerId: [
          {
            message: this.$t("handleAdsPageValidationProvider"),
            trigger: "change",
            required: true,
          },
        ],
        navigationProvideId: [
          {
            message: this.$t("handleAdsPageValidationProvider"),
            trigger: "change",
            required: true,
          },
        ],
        startDate: [
          {
            required: true,
            message: "Please input start date",
            trigger: "blur",
          },
          {
            validator: validateStartDate,
            trigger: "blur",
          },
        ],
        endDate: [
          {
            required: true,
            message: "Please input end date",
            trigger: "blur",
          },
          {
            validator: validateEndDate,
            trigger: "blur",
          },
        ],
      },
      showAreaFilters: false,
    };
  },
  computed: {
    ...mapGetters({ lookups: "getAllLookups" }),
    isBannerMode() {
      return this.adObj.type == "banner";
    },
    isPopupMode() {
      return this.adObj.type == "popup";
    },
  },
  methods: {
    uploadEntitiesFileChange(file) {
      // Allow only one file in the file list not multiple
      this.adObj.entitiesExcelFileList = [file];
    },
    removeEntitiesFile() {
      this.adObj.entitiesExcelFileList = [];
    },
    placementChanged() {
      this.$refs.AdForm.clearValidate();
      // if (this.adObj.placement == "section") this.adObj.providerId = [];
      // if (this.adObj.placement == "provider") {
      //   this.adObj.navigation = "deep_link";
      //   this.adObj.targeting = "areaTargeting";
      // }
      // if (this.adObj.placement == "provider") {
      //   this.adObj.sections = [];
      // }
      // if (this.adObj.placement == "homepage") {
      //   this.adObj.sections = [];
      //   this.adObj.providerId = [];
      // }
    },
    targetingChanged() {
      this.$refs.AdForm.clearValidate();
      // if (this.adObj.targeting == "areaTargeting" && this.adObj.usersTargeting.length > 0) this.adObj.usersTargeting = []
    },
    targetChanged() {
      this.$refs.AdForm.clearValidate();
      // if (this.adObj.navigation == "section"){
      //   this.adObj.navigationProvideId = [];
      //   this.adObj.deepLink = '';
      // }
      // if (this.adObj.navigation == "provider") {
      //   this.adObj.sectionsNavigation = [];
      //    this.adObj.deepLink = '';
      // }
      // if (this.adObj.navigation == "deepLink") {
      //   this.adObj.sectionsNavigation = [];
      //   this.adObj.navigationProvideId = [];
      // }
    },
    async providerChanged(state) {
      this.adObj.areas = [];
      this.adObj.regions = [];
      // this.adObj.sections = [];
      // this.getProviderSections();
      state === "placement"
        ? this.getProviderPlaces("placement")
        : this.getProviderPlaces("navigation");
    },
    // async getUsers(query) {
    //   if (query !== "") {
    //     try {
    //       const users = await retailersService.getRetailers(1, { name: query });
    //       this.usersList = users.retailersList;
    //     } catch (err) {
    //       console.log(err);
    //     }
    //   }
    //   return;
    // },
    async getProviderPlaces(state) {
      try {
        const areas = await providersService.getProviderAreaAds(
          state === "placement"
            ? this.adObj.providerId
            : this.adObj.navigationProvideId
          // this.adObj.sections.map((section) => section.key)
        );
        const regions = await providersService.getProviderRegionAds(
          // this.adObj.providerId
          state === "placement"
            ? this.adObj.providerId
            : this.adObj.navigationProvideId
          // this.adObj.sections.map((section) => section.key)
        );
        this.adObj.destination = this.adObj.providerId;
        this.adObj.areasList = areas.data.data;
        this.adObj.regionsList = regions.data.data;
        this.adObj.areas = areas.data.data.map((area) => area.id);
        this.adObj.regions = regions.data.data.map((region) => region.id);
      } catch (err) {
        console.log(err);
      }
    },
    async getProviderSections() {
      try {
        const sections = await sectionsService.getProviderSection(
          this.adObj.providerId
        );
        this.adObj.sections = [];
        this.adObj.sectionsList = sections.data.sections;
      } catch (err) {
        console.log(err);
      }
    },
    regionsChanged(regions) {
      this.showAreaFilters = regions.length && true;
      if (regions.length === 0) this.adObj.areasList = [];
    },
    async filterAreasByRegion() {
      try {
        const {
          data: {
            data: { areas },
          },
        } = await regionsService.getAreasLookup({
          filter: { regionId: this.adObj.regions },
        });

        this.adObj.areasList = areas.map((a) => ({ id: a.key, name: a.value }));
        this.adObj.areas = areas.map((a) => a.key);
        this.showAreaFilters = false;
      } catch (err) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(err),
          type: "error",
        });
      }
    },
    playVideo() {
      const videoPlayer = this.$refs["videoPlayer"];

      if (videoPlayer.paused) {
        videoPlayer.play();
      } else videoPlayer.pause();
    },
    openEditMedia() {
      this.$refs["mediaFile"].click();
    },
    editMedia(event) {
      this.$emit("media-edit", {
        newUri: event.target.files[0],
        ad: this.adObj,
      });
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.adObj.name = newValue.name;
        this.adObj.imageUrl = newValue.imageUrl;
      },
    },
    adObj: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
    // "adObj.sectionType": {
    //   deep: true,
    //   handler(val) {
    //     if (val == "section") {
    //       this.adObj.providerId = undefined;
    //       this.adObj.destination = undefined;
    //     }
    //     this.adObj.areasList = [];
    //     this.adObj.regionsList = [];
    //     this.adObj.sectionsList = null;
    //     this.adObj.areas = [];
    //     this.adObj.regions = [];
    //     this.showAreaFilters = false;
    //   },
    // },
    "adObj.type": {
      deep: true,
      handler(val) {
        // reset unwanted values when changing ad type
        if (val == "popup") {
          this.adObj.providerId = undefined;
          this.adObj.destination = undefined;
          this.adObj.areasList = [];
          this.adObj.regionsList = [];
          this.adObj.sectionsList = null;
          this.adObj.areas = [];
          this.adObj.regions = [];
          this.adObj.sectionType = "section";
          this.showAreaFilters = false;
        } else if (val == "banner") {
          this.adObj.ad_config = {};
          this.adObj.mediaType = "image";
        }
      },
    },

    "adObj.mediaType": {
      handler(val) {
        if (val == "video") {
          this.adObj.ad_config.time_to_end = undefined;
          this.adObj.ad_config.is_playable = true;
        } else {
          this.adObj.ad_config.is_playable = undefined;
        }
      },
    },
  },
};
</script>

<template>
  <el-card shadow="hover">
    <el-form
      :model="adObj"
      @submit.native.prevent.stop="saveAd"
      ref="AdForm"
      :rules="adRules"
      :inline="false"
      size="normal"
    >
      <el-collapse :value="!isCollapsed ? 'ad-Details' : ''">
        <el-collapse-item name="ad-Details">
          <!-- *********************************************************** -->
          <!-- Card-Header -->
          <!-- *********************************************************** -->
          <template slot="title">
            <div class="ad-card_header" @click.stop="">
              <div class="ad-card_header_lead">
                <input
                  :disabled="disabled"
                  type="file"
                  ref="mediaFile"
                  @change="editMedia($event)"
                  style="display: none"
                />
                <el-form-item :prop="`${adObj.mediaType}Url`" size="normal">
                  <action-container
                    class="videoPlayer"
                    v-if="adObj.mediaType == 'video'"
                  >
                    <video
                      ref="videoPlayer"
                      v-if="adObj.videoUrl"
                      :src="adObj.videoUrl"
                    ></video>
                    <div class="media-placeholder" v-else>
                      {{ $t("handleAdsPageVideoPlaceholder") }}
                    </div>
                    <div slot="action-btn">
                      <el-button
                        type="primary"
                        :disabled="disabled || adPublished"
                        circle
                        icon="el-icon-edit"
                        @click="openEditMedia"
                        size="normal"
                      >
                      </el-button>
                      <el-button
                        type="info"
                        v-if="adObj.videoUrl"
                        icon="el-icon-video-play"
                        round
                        size="normal"
                        @click="playVideo"
                      >
                        {{ $t("handleAdsPagePlayBtn") }}
                      </el-button>
                    </div>
                  </action-container>
                  <action-container v-else class="videoPlayer">
                    <img
                      v-if="adObj.imageUrl"
                      :src="adObj.imageUrl"
                      alt=""
                      srcset=""
                    />
                    <div class="media-placeholder" v-else>
                      {{ $t("handleAdsPageImagePlaceholder") }}
                    </div>

                    <div slot="action-btn">
                      <el-button
                        type="primary"
                        :disabled="disabled || adPublished"
                        circle
                        icon="el-icon-edit"
                        @click="openEditMedia"
                        size="normal"
                      >
                      </el-button>
                    </div>
                  </action-container>
                </el-form-item>
              </div>
              <div class="mid">
                <el-form-item label="Ad Name" prop="name" size="normal">
                  <el-input
                    :disabled="disabled"
                    v-model="adObj.name"
                    :placeholder="$t('handleAdsPagePlaceholderName')"
                    size="normal"
                    clearable
                  ></el-input>
                </el-form-item>
              </div>
              <div class="tail">
                <div class="u-bottom-margin--2x">
                  <slot name="actions"></slot>
                  <!--check if slot passed -->
                  <el-button
                    v-if="!$slots.actions"
                    :disabled="disabled"
                    size="medium"
                    type="primary"
                    native-type="submit"
                    >{{ $t("handleAdPageButtonLabelSave") }}</el-button
                  >
                </div>
                <el-form-item>
                  <el-radio-group
                    :disabled="disabled || adPublished"
                    v-model="adObj.type"
                  >
                    <!-- <el-radio-button label="popup">{{
                      $t("handleAdPagePopupOptionLabel")
                    }}</el-radio-button> -->
                    <el-radio-button label="banner">{{
                      $t("handleAdPageBannerOptionLabel")
                    }}</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <!-- <el-form-item>
                  <el-radio-group v-model="adObj.visible" :disabled="disabled">
                    <el-radio-button :label="true">{{
                      $t("handleAdPageActiveOptionLabel")
                    }}</el-radio-button>
                    <el-radio-button :label="false">{{
                      $t("handleAdPageInActiveOptionLabel")
                    }}</el-radio-button>
                  </el-radio-group>
                </el-form-item> -->
              </div>
            </div>
          </template>
          <!-- *********************************************************** -->
          <!-- Card-body -->
          <!-- *********************************************************** -->
          <template v-if="isBannerMode">
            <!-- Placement -->
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  label="Placement"
                  label-width="100px"
                  size="normal"
                >
                  <el-radio-group
                    :disabled="disabled"
                    v-model="adObj.placement"
                    size="medium"
                    @change="placementChanged"
                  >
                    <el-radio-button label="provider">Provider</el-radio-button>
                    <el-radio-button label="section">Section</el-radio-button>
                    <el-radio-button label="home">Home Page</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <div class="ad-card_body-col">
                  <template v-if="adObj.placement == 'provider'">
                    <el-form-item prop="providerId">
                      <el-select
                        @change="providerChanged('placement')"
                        key="providerSelectComponent"
                        style="width: 100%"
                        v-model="adObj.providerId"
                        value-key="providerId"
                        :disabled="disabled"
                        :placeholder="$t('handleAdsPagePlaceholderProvider')"
                        clearable
                        filterable
                        :value="{ providerId: item }"
                      >
                        <!-- :value="{ providerId: item.id }" -->
                        <el-option
                          v-for="item in lookups.distributers"
                          :key="item.id"
                          :disabled="disabled"
                          :label="item.name"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </template>

                  <template v-if="adObj.placement == 'section'">
                    <el-form-item prop="sections">
                      <!-- @change="
                        adObj.sectionType == 'provider'
                          ? getProviderPlaces()
                          : null
                      " -->
                      <el-select
                        style="width: 100%"
                        :placeholder="$t('handleAdsPagePlaceholderSections')"
                        v-model="adObj.sections"
                        :disabled="disabled"
                        value-key="key"
                        clearable
                        filterable
                        :value="{ key: item, target: 'section' }"
                      >
                        <!-- :value="{ key: item.key, target: 'section' }" -->
                        <el-option
                          v-for="item in adObj.sectionsList
                            ? adObj.sectionsList
                            : lookups.sections"
                          :key="item.key"
                          :label="item.title"
                          :value="item.key"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </template>

                  <el-form-item v-if="adObj.placement == 'home'">
                    <el-alert
                      :closable="false"
                      title="This Banner Will Display In The Home Page"
                      type="warning"
                      show-icon
                    >
                    </el-alert>
                  </el-form-item>
                </div>
              </el-col>
              <!-- Placement -->
              <!-- Navigation -->
              <el-col :span="12">
                <el-form-item
                  label="Navigation"
                  label-width="100px"
                  size="normal"
                >
                  <el-radio-group
                    :disabled="disabled"
                    v-model="adObj.navigation"
                    size="medium"
                    @change="targetChanged"
                  >
                    <el-radio-button
                      label="provider"
                      :disabled="
                        adObj.placement == 'provider' ||
                        adObj.placement == 'home'
                      "
                      >Provider</el-radio-button
                    >
                    <el-radio-button
                      label="section"
                      :disabled="adObj.placement == 'provider'"
                      >Section</el-radio-button
                    >
                    <el-radio-button label="deep_link"
                      >Deeplink</el-radio-button
                    >
                    <el-radio-button v-if="!adPublished" label="none"
                      >None</el-radio-button
                    >
                  </el-radio-group>
                </el-form-item>
                <!-- @change="providerChanged"  -->
                <div class="ad-card_body-col">
                  <template v-if="adObj.navigation == 'provider'">
                    <el-form-item prop="navigationProvideId">
                      <el-select
                        @change="providerChanged('navigation')"
                        key="providerSelectComponent"
                        v-model="adObj.navigationProvideId"
                        :disabled="disabled"
                        :placeholder="$t('handleAdsPagePlaceholderProvider')"
                        clearable
                        filterable
                        :value="{ providerId: item }"
                      >
                        <el-option
                          v-for="item in lookups.distributers"
                          :key="item.id"
                          :disabled="disabled"
                          :label="item.name"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </template>

                  <template v-if="adObj.navigation == 'section'">
                    <el-form-item prop="sectionsNavigation">
                      <el-select
                        style="width: 100%"
                        :placeholder="$t('handleAdsPagePlaceholderSections')"
                        v-model="adObj.sectionsNavigation"
                        :disabled="disabled"
                        clearable
                        filterable
                      >
                        <el-option
                          v-for="item in adObj.sectionsList
                            ? adObj.sectionsList
                            : lookups.sections"
                          :key="item.key"
                          :label="item.title"
                          :value="item.key"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </template>

                  <el-form-item
                    v-if="adObj.navigation == 'deep_link'"
                    prop="deep_link"
                  >
                    <el-input
                      :disabled="disabled"
                      v-model="adObj.deep_link"
                      placeholder="Enter Deeplink URL"
                      size="normal"
                      clearable
                    ></el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>

            <!-- Navigation -->
            <!-- Targeting -->
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  label="Targeting"
                  label-width="100px"
                  size="normal"
                >
                  <el-radio-group
                    :disabled="disabled"
                    v-model="adObj.targeting"
                    size="medium"
                    @change="targetingChanged"
                  >
                    <el-radio-button label="areaTargeting"
                      >Area Targeting</el-radio-button
                    >
                    <el-radio-button label="user"
                      >User IDs Targeting</el-radio-button
                    >
                  </el-radio-group>
                </el-form-item>
                <div class="ad-card_body-col">
                  <template v-if="adObj.targeting == 'areaTargeting'">
                    <el-form-item>
                      <template v-if="!adObj.regionsList.length">
                        <div class="u-display-flex u-align-items--center">
                          <dropdownCategoriesSelect
                            :disabled="disabled"
                            @change="regionsChanged"
                            v-model="adObj.regions"
                          />
                          <el-button
                            class="u-left-margin--2x"
                            v-if="showAreaFilters"
                            type="primary"
                            size="small"
                            @click="filterAreasByRegion"
                            >Filter Areas</el-button
                          >
                        </div>
                      </template>
                      <el-select
                        :disabled="disabled"
                        v-else
                        v-model="adObj.regions"
                        value-key=""
                        :placeholder="$t('handleAdsPagePlaceholderRegions')"
                        clearable
                        multiple=""
                        filterable
                      >
                        <el-option
                          v-for="item in adObj.regionsList"
                          :key="item.id"
                          :label="item.region"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item prop="areas">
                      <dropdownSelectAll
                        :disabled="disabled"
                        v-model="adObj.areas"
                        :source="
                          adObj.areasList.length
                            ? adObj.areasList
                            : lookups.areas
                        "
                        :placeholder="$t('handleAdsPagePlaceholderSelectAreas')"
                        clearable
                        filterable
                      >
                        <template
                          v-slot:optionBody
                          v-if="!adObj.areasList.length"
                        >
                          <el-option
                            v-for="item in lookups.areas"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key"
                          >
                          </el-option>
                        </template>
                        <template v-slot:optionBody v-else>
                          <el-option
                            v-for="item in adObj.areasList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                        </template>
                      </dropdownSelectAll>
                    </el-form-item>
                  </template>
                  <template v-if="adObj.targeting == 'user'">
                    <div class="userAssignationContainer">
                      <el-alert
                        class="userAssignationAlert"
                        :closable="false"
                        title="Banner Bulk Assignation For Users"
                        type="warning"
                        show-icon
                      >
                      </el-alert>
                      <div class="uploadContainer">
                        <el-upload
                          :disabled="disabled"
                          :on-change="uploadEntitiesFileChange"
                          :on-remove="removeEntitiesFile"
                          :file-list="adObj.entitiesExcelFileList"
                          action="#"
                          :multiple="false"
                          :auto-upload="false"
                          accept=".xlsx"
                        >
                          <el-button
                            :disabled="disabled"
                            slot="trigger"
                            size="mini"
                            type="primary"
                            >{{ $t("bundleSelectFiles") }}</el-button
                          >
                        </el-upload>
                      </div>
                    </div>

                    <!-- @change="
                        adObj.sectionType == 'provider'
                          ? getProviderPlaces()
                          : null
                      " -->
                    <!-- <el-form-item prop="">
                     
                      <el-select
                        :disabled="disable"
                        v-model="adObj.usersTargeting"
                        multiple
                        filterable
                        remote
                        reserve-keyword
                        placeholder="Search Users By Name"
                        :remote-method="getUsers"
                      >
                        <el-option
                          v-for="user in usersList"
                          :key="user.id"
                          :label="user.name"
                          :value="user.id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item> -->
                  </template>
                </div>
              </el-col>

              <!-- Targeting -->

              <!-- Call To Action Button -->
              <el-col :span="12">
                <div class="customFormItemWrapperr">
                  <el-form-item label="Call To Action" size="normal">
                    <el-switch
                      v-model="adObj.isCallToAction"
                      :disabled="disabled"
                    ></el-switch>
                  </el-form-item>
                  <el-form-item v-if="adObj.isCallToAction === true">
                    <el-input
                      maxlength="14"
                      :disabled="disabled"
                      v-model="adObj.btnName"
                      placeholder="Button Name"
                      size="normal"
                      clearable
                    ></el-input>
                  </el-form-item>
                </div>
              </el-col>

              <!-- Call To Action Button -->
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  :label="`Start Date`"
                  prop="startDate"
                  label-width="120px"
                >
                  <el-date-picker
                    :disabled="disabled"
                    v-model="adObj.startDate"
                    type="datetime"
                    placeholder="Select date and time"
                    value-format="timestamp"
                    style="width: 100%"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label-width="120px"
                  :label="`End Date`"
                  prop="endDate"
                >
                  <el-date-picker
                    :disabled="disabled"
                    v-model="adObj.endDate"
                    type="datetime"
                    placeholder="Select date and time"
                    value-format="timestamp"
                    style="width: 100%"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </template>

          <div class="ad-card_body">
            <!-- ********************* -->
            <!-- Body-col -->
            <!-- ********************* -->

            <div class="ad-card_body-col">
              <template v-if="isPopupMode">
                <el-form-item
                  :label="$t('handleAdsPagePlaceholderTimeToOpen')"
                  label-width="100px"
                  size="normal"
                >
                  <el-input
                    v-model="adObj.ad_config.time_to_open"
                    :disabled="disabled || adPublished"
                    placeholder=""
                    size="normal"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item
                  v-if="adObj.mediaType == 'image'"
                  :label="$t('handleAdsPagePlaceholderTimeToEnd')"
                  label-width="100px"
                  size="normal"
                >
                  <el-input
                    :disabled="disabled || adPublished"
                    v-model="adObj.ad_config.time_to_end"
                    placeholder=""
                    size="normal"
                    clearable
                  ></el-input>
                </el-form-item>

                <el-form-item
                  :label="$t('handleAdsPagePlaceholderTimeToClose')"
                  label-width="100px"
                  size="normal"
                >
                  <el-input
                    :disabled="disabled || adPublished"
                    v-model="adObj.ad_config.time_to_close"
                    size="normal"
                    clearable
                  ></el-input>
                </el-form-item>
              </template>
              <el-form-item
                v-if="isPopupMode"
                :label="$t('handleAdsPageLabelSection')"
                prop="sections"
                label-width="100px"
                size="normal"
              >
                <el-select
                  v-model="adObj.sections"
                  @change="
                    adObj.sectionType == 'provider' ? getProviderPlaces() : null
                  "
                  value-key="key"
                  :disabled="disabled || adPublished"
                  :placeholder="$t('handleAdsPagePlaceholderSections')"
                  clearable
                  filterable
                  multiple
                >
                  <el-option
                    v-for="item in adObj.sectionsList
                      ? adObj.sectionsList
                      : lookups.sections"
                    :key="item.key"
                    :label="item.title"
                    :value="{ key: item.key, target: 'section' }"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <!-- ********************* -->
            <!-- Body-col -->
            <!-- ********************* -->
            <div class="ad-card_body-col" v-if="isPopupMode">
              <el-form-item label="Regions" label-width="80px" size="normal">
                <template v-if="!adObj.regionsList.length">
                  <div class="u-display-flex u-align-items--center">
                    <dropdownCategoriesSelect
                      :disabled="disabled || adPublished"
                      @change="regionsChanged"
                      v-model="adObj.regions"
                    />
                    <el-button
                      class="u-left-margin--2x"
                      v-if="showAreaFilters"
                      type="primary"
                      size="small"
                      @click="filterAreasByRegion"
                      >Filter Areas</el-button
                    >
                  </div>
                </template>
                <el-select
                  :disabled="disabled || adPublished"
                  v-else
                  v-model="adObj.regions"
                  value-key=""
                  :placeholder="$t('handleAdsPagePlaceholderRegions')"
                  clearable
                  multiple=""
                  filterable
                >
                  <el-option
                    v-for="item in adObj.regionsList"
                    :key="item.id"
                    :label="item.region"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label-width="80px"
                :label="$t('handleAdsPageLabelAreas')"
                prop="areas"
                size="normal"
              >
                <dropdownSelectAll
                  v-model="adObj.areas"
                  :source="
                    adObj.areasList.length ? adObj.areasList : lookups.areas
                  "
                  :disabled="disable || adPublished"
                  :placeholder="$t('handleAdsPagePlaceholderSelectAreas')"
                  clearable
                  filterable
                >
                  <template v-slot:optionBody v-if="!adObj.areasList.length">
                    <el-option
                      v-for="item in lookups.areas"
                      :key="item.key"
                      :label="item.value"
                      :value="item.key"
                    >
                    </el-option>
                  </template>
                  <template v-slot:optionBody v-else>
                    <el-option
                      v-for="item in adObj.areasList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </template>
                </dropdownSelectAll>
              </el-form-item>
              <el-form-item
                :label="$t('handleAdsPageLabelDestnation')"
                v-if="adObj.sectionType == 'provider'"
                label-width="80px"
                size="normal"
              >
                <el-select
                  v-model="adObj.destination"
                  disabled
                  value-key=""
                  :placeholder="$t('handleAdsPagePlaceholderProvider')"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in lookups.distributers"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <el-table
            v-if="adObj.sections && adObj.sectionType == 'provider'"
            :data="adObj.sections"
          >
            <el-table-column label="Section" prop="key"> </el-table-column>
            <el-table-column label="Appear In">
              <template slot-scope="scope">
                <el-select v-model="scope.row.target" clearable filterable>
                  <el-option
                    v-for="item in targetOptions"
                    :key="item.key"
                    :label="item.label"
                    :value="item.key"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>
    </el-form>
  </el-card>
</template>

<style lang="scss" scoped>
.userAssignationContainer {
  background: #c1c1c10f;
  padding: 16px;
  margin: 25px 9px;
  box-shadow: 0px 0px 4px 0px #cac5c5;
  border-radius: 5px;
}

.uploadContainer {
  .el-upload {
    button {
      span {
        font-size: 14px;
      }
    }
  }
  .el-button.el-button--info {
    border-left: unset;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    border-color: #f4f4f5;
    span {
      font-size: 14px;
    }
  }
}
// *********************************************************
// card header styles
// *********************************************************
.el-collapse {
  border: none;
}
::v-deep .el-collapse-item__header {
  padding: 2rem;
  font-size: inherit;
  border: none;
  height: auto !important;
  background: transparent;
  line-height: auto;
}
.ad-card_header {
  width: 90%;
  display: flex;
  align-items: center;
  img {
    max-width: 100%;
    height: auto;
  }
  .ad-card_header_lead {
    max-width: 33%;
    flex-basis: 33%;
  }
  .mid {
    flex-basis: 33%;
    margin: 0 1rem;
  }
  .tail {
    margin-left: auto;
    flex-basis: 33%;
    text-align: center;
  }
}
// *********************************************************
// card body styles
// *********************************************************
.ad-card_body {
  display: flex;
  padding: 2rem;
  gap: 2.5rem;
}
.ad-card_body-col {
  flex-basis: 50%;
  .el-select,
  .el-input {
    width: 100%;
  }
}
.video-action {
  font-size: 1.5rem;
}
video {
  width: 100%;
  height: auto;
}
::v-deep .el-collapse-item__wrap {
  background: transparent;
}
.media-placeholder {
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  background: rgba(128, 128, 128, 0.176);
}
.userAssignationAlert {
  margin-bottom: 20px;
}
</style>
